@tailwind base;
@tailwind components;
@tailwind utilities;

.landing-page {
  font-family: 'Arial', sans-serif; /* O la fuente que prefieras */
}

.navbar {
  background-image: linear-gradient(to right, #2c3e50, #3498db); /* Gradiente azul oscuro a azul claro */
  color: white;
  padding: 1rem; /* Margen interno */
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 0 1rem; /* Margen lateral */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

main {
  padding: 2rem; /* Márgenes en el contenido principal */
}

main p {
  line-height: 1.6; /* Interlineado para mejor legibilidad */
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Puedes probar con 'cover' o 'contain' según tus necesidades */
}